<template>
  <div
    v-if="getContent"
    class="bp-sticky-banner flex--row-center-center"
    :style="`background: ${getContent.backgroundColor}`"
    ref="siteBannerContainer"
  >
    <div
      class="bp-sticky-banner__content"
      v-if="!shouldScroll"
      ref="textContainerOne"
    >
      <RichText
        class="bp-sticky-banner__text-line-1 text-zd-banner"
        :content="getContent.textLine1"
      />
      <RichText
        class="bp-sticky-banner__text-line-2 text-zd-banner"
        :content="getContent.textLine2"
        v-if="getContent.textLine2"
      />
    </div>
    <MarqueeText
      class="bp-sticky-banner__marquee"
      v-else
      :repeat="10"
      :duration="15"
      ref="textContainerTwo"
    >
      <RichText
        class="bp-sticky-banner__text-line-1 bp-sticky-banner__scrollable-text text-zd-banner"
        :content="getContent.textLine1"
        ref="textOne"
      />
      <RichText
        class="bp-sticky-banner__text-line-2 bp-sticky-banner__scrollable-text text-zd-banner"
        :content="getContent.textLine2"
        v-if="getContent.textLine2"
        ref="textTwo"
      />
    </MarqueeText>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex"
import RichText from "@/components/RichText"
import MarqueeText from "vue-marquee-text-component"
import ContentfulMixin from "../../contentfulComponents/ContentfulMixin"

export default {
  mixins: [ContentfulMixin],

  contentfulModel: "SiteBanner",

  data() {
    return {
      lastScrollPosition: 0,
      shouldScroll: false,
      maxTextSize: 0
    }
  },
  components: {
    RichText,
    MarqueeText
  },

  computed: {
    ...mapState("scroll", ["scrollPos", "autoScroll"]),
    ...mapState("breakpoints", ["currentWidth"]),
    ...mapGetters("content", ["GET_SITE_CONFIG"]),
    ...mapGetters("breakpoints", ["GET_IS_ABOVE_BREAKPOINT"]),

    getIsScrollOptionSelected() {
      return !!this.getContent.enableScrollingText
    }
  },

  mounted() {
    this.setMaxTextSize()
    this.updateShouldScroll(this.currentWidth)
    if (this.getIsScrollOptionSelected) {
      this.shouldScroll = true
    }
  },

  beforeDestroy() {},
  methods: {
    ...mapMutations("scroll", ["SET_SHOW_BANNER"]),

    onScroll(currentScrollPosition) {
      if (currentScrollPosition < 0) {
        return
      }

      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 70) {
        return
      }

      this.SET_SHOW_BANNER(currentScrollPosition < this.lastScrollPosition)
      this.lastScrollPosition = currentScrollPosition
    },

    setMaxTextSize() {
      this.$refs.siteBannerContainer.style.width = "3000px"
      this.maxTextSize = this.$refs.textContainerOne.offsetWidth + 20
      this.$refs.siteBannerContainer.style.width = "100%"
    },

    updateShouldScroll(pageWidth) {
      this.shouldScroll =
        this.getContent.enableScrollingText ||
        (!this.getIsScrollOptionSelected && this.maxTextSize > pageWidth)
    }
  },

  watch: {
    scrollPos(newVal) {
      if (!this.autoScroll) this.onScroll(newVal)
    },

    currentWidth(newVal) {
      this.updateShouldScroll(newVal)
    }
  }
}
</script>

<style lang="scss">
.bp-sticky-banner {
  color: $bleach-black;

  height: $banner-height-default;
  width: 100%;

  position: fixed;
  top: calc(#{$nav-height-default} - #{$banner-height-default});
  transition: top $banner-animation-speed $banner-animation-ease;
  z-index: 9; // Menu z-index - 1

  @include media-breakpoint-up(sm) {
    height: $banner-height-sm;
    top: $nav-height-default - $banner-height-sm;
  }

  @include media-breakpoint-up(md) {
    top: $nav-height-md - $banner-height-sm;
  }

  @include media-breakpoint-up(lg) {
    height: $banner-height-lg;
    top: calc(#{$nav-height-lg} + #{$menu-height-lg}) - #{$banner-height-lg};
  }

  @include media-breakpoint-up(xl) {
    height: $banner-height-lg;
    top: ($nav-height-xl + $menu-height-lg) - $banner-height-lg;
  }

  .show-banner.active-banner & {
    top: $nav-height-default;

    @include media-breakpoint-up(md) {
      top: $nav-height-md;
    }

    @include media-breakpoint-up(lg) {
      top: $nav-height-lg + $menu-height-lg;
    }

    @include media-breakpoint-up(xl) {
      top: $nav-height-xl + $menu-height-lg;
    }
  }

  .show-banner.active-banner.cart-alert-active & {
    top: $nav-height-default + $cart-alert-height-default;

    @include media-breakpoint-up(md) {
      top: $nav-height-md + $cart-alert-height-md;
    }

    @include media-breakpoint-up(lg) {
      top: ($nav-height-lg + $menu-height-lg) + $cart-alert-height-md;
    }

    @include media-breakpoint-up(xl) {
      top: ($nav-height-xl + $menu-height-lg) + $cart-alert-height-md;
    }
  }

  &__marquee {
    height: 1.5em;

    .marquee-text-text {
      display: flex;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__text-line {
    &-1 {
      p {
        margin-bottom: 0;

        @include media-breakpoint-down(xs) {
          font-size: 1rem;
        }
      }
    }

    &-2 {
      margin-left: 30px;

      @include media-breakpoint-down(xs) {
        margin-left: 20px;
      }

      p {
        margin: unset;

        @include media-breakpoint-down(xs) {
          font-size: 1rem;
        }
      }
    }
  }

  &__scrollable-text {
    margin-left: 30px;

    @include media-breakpoint-down(xs) {
      margin-left: 20px;
    }
  }
}
</style>
